import React, { useState } from "react"
import Img from "gatsby-image"
import { navigate } from "@reach/router"
import {
  Row,
  Col,
  Button,
  Form,
  FormToggle,
  InputGroup,
  FormControl,
  FormCheck,
} from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"
import axios from "axios"

const Contact = () => {
  const [formValues, setFormValues] = useState({ signup: "off" })
  const handleSubmit = event => {
    event.preventDefault()
    // if (formValues.signup === "on") {
    //   // ALSO SIGNUP FOR NEWSLETTER
    //   axios
    //     .post(`${process.env.SERVER_URL}/contact`, {
    //       values: formValues,
    //       newsletter: true,
    //     })
    //     .then(res => {
    //       // POST TO RAPIDMAIL
    //       navigate("/success")
    //     })
    // } else {
    // ONLY SEND EMAIL
    axios
      .post(`${process.env.SERVER_URL}/contact`, {
        values: formValues,
        newsletter: false,
      })
      .then(res => {
        navigate("/success")
      })
    // }
  }

  const handleChange = ({ target }) => {
    const name = target.name
    const value = target.value
    console.log(target.name, target.value)
    setFormValues({ ...formValues, [name]: value })
  }
  return (
    <Layout style={{ backgroundColor: "#fab69fff" }}>
      <SEO title="Contact" />
      <Row className="mx-0" style={{ marginTop: "-73px" }}>
        <Col className="px-0">
          <div className="w-100 d-flex justify-content-center align-items-center contact-section">
            <div style={{ height: "70vh" }}>
              <h1 className="sectionTitle">Contact</h1>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center align-items-center contact-form">
        <Col xs={10} lg={8}>
          <Form className="mt-5" onSubmit={handleSubmit}>
            <Row>
              <Col xs={6}>
                <Form.Control
                  size="lg"
                  placeholder="first name"
                  name="firstName"
                  type="text"
                  required
                  onChange={handleChange}
                />
              </Col>
              <Col xs={6}>
                <Form.Control
                  size="lg"
                  placeholder="last name"
                  name="lastName"
                  type="text"
                  required
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <Form.Control
                  size="lg"
                  placeholder="email address"
                  name="email"
                  type="email"
                  required
                  onChange={handleChange}
                />
              </Col>
              <Col xs={6}>
                <Form.Control
                  size="lg"
                  as="select"
                  placeholder="Interested as..."
                  name="interest"
                  onChange={handleChange}
                >
                  <option disabled selected>
                    Interested as...
                  </option>
                  <option name="teacher">Teacher</option>
                  <option name="connector">Connector</option>
                  <option name="lightschool">Lightschool</option>
                  <option name="student">Student</option>
                  <option name="organization">
                    Organization & Authorities
                  </option>
                  <option name="general">General</option>
                </Form.Control>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Control
                    as="textarea"
                    type="text"
                    rows="3"
                    placeholder="message"
                    required
                    className="mb-0"
                    name="message"
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Check
                  type="checkbox"
                  className="mb-3"
                  label="Check this box if you would like to stay informed on our events & developments"
                  name="signup"
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row className="d-flex justify-content-center align-items-center mb-5 pb-5">
              <Col xs={4} lg={2}>
                <Button className="send" type="submit" value="submit">
                  SEND
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Row className="d-flex flex-column align-items-center mediaKit pb-5">
        <Col xs={10} lg={8}>
          <h1>MEDIA KIT</h1>
          <p>
            Download our{" "}
            <a
              href="https://drive.google.com/drive/folders/1EGHYkBbJ9wuuIqgAGZ3_YjIjStrNSDib?usp=sharing"
              target="_blank"
              style={{ color: "white" }}
            >
              <strong>media kit</strong>
            </a>{" "}
            if you would like to feature Lightschools in your blog, magazine or
            newspaper. If you would like to speak to us personally, please leave
            a message through the form above. We love to share publicity so
            please don’t forget to send us your articles or stories.
          </p>
        </Col>
      </Row>
    </Layout>
  )
}

export default Contact
